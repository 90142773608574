.title {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    .mediumFont{
        width: 45%;
        @media (max-width: 1000px) {
            width: 70%;
        }
        @media (max-width: 650px) {
            width: 100%;
        }
    }
}
