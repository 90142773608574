.footer {
    background: linear-gradient(var(--primary) 50%, var(--tertiary));
    padding: 0.5rem;

    .top-footer{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1.5rem;
        @media (max-width: 650px) {
            padding: 1.5rem 0 1.5rem 0;
        }
        .websites{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            column-gap: 2rem;
            font-size: 2rem;
            line-height: 1.5rem;
            @media (max-width: 650px) {
                font-size: 1.5rem;
                column-gap: 0.2rem;
                justify-content: space-around;
            }
            .line{
                @media (max-width: 650px) {
                     display: none;
                }
                width: 100%;
                height: 1px;
                background-color: var(--secondary-light);
            }
            .link{
                color: var(--secondary);
                cursor: pointer;
                transition: scale 1s;
                &:hover{
                    scale: 1.2;
                }
            }
            .contact-link{
                display: flex;
                align-items: center;
                position: relative;
                column-gap: 0.3rem;
                .contact{
                    display: inline-block;
                    overflow: hidden;
                    white-space: nowrap;
                    color: var(--secondary);
                    font-size: 0.8rem;
                    opacity: 0;
                    max-width: 0;
                    transition: max-width 2s, opacity 2s;
                    @media (max-width: 650px) {
                        font-size: 0.7rem;
                    }
                    &.show{
                        max-width: 100%;
                        opacity: 1;
                    }
                }
            }
        }
    }
    .bottom-footer{
        color: var(--secondary);
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        column-gap: 0.2rem;
        font-style: italic;
        font-size: 0.8rem;
        text-align: center;
        @media (max-width: 650px) {
            font-size: 0.7rem;
        }
    }
}
