.experiences-and-education{
    .items{
        position: relative;
        display: flex;
        flex-direction: column;
        margin-top: 2rem;
        gap: 8rem;
        width: 100%;
        .timeline{
            position: absolute;
            width: 6px;
            height: 100%;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            background-color: var(--primary);
            &::before{
                content: '';
                position: absolute;
                top: -20px;
                left: 50%;
                transform: translateX(-50%);
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-bottom: 20px solid var(--primary);
            }
        }
        .item{
            position: relative;
            overflow: hidden;
            &::before{
                content: '';
                position: absolute;
                top: 50%;
                right: 50%;
                transform: translate(0, -50%);
                background-color: var(--primary);
                width: 20px;
                height: 2px;
            }
        }
        .right::before{
            right: auto;
            left: 50%;
        }
        @media(max-width: 1000px) {
            .arrow, .timeline, .item::before {
                display: none;
            }
        }
    }
}
