.certifications {
    .certifications-list{
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 8rem;
        overflow: hidden;

        .certification {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            gap: 2rem;

            .image {
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                    width: 150px;
                    transition: transform 0.5s;
                    &:hover{
                        transform: scale(1.1);
                    }
                }
            }

            .text {
                display: flex;
                align-items: center;
                flex-direction: column;
                max-width: 900px;
                text-align: center;
                row-gap: 0.5rem;

                a {
                    font-size: 1.8rem;
                    &::after {
                        content: '';
                        position: absolute;
                        left: 50%;
                        transform: translateX(-50%);
                        bottom: -1px;
                        height: 2px;
                        width: 0;
                        background: var(--link);
                        transition: transform 0.8s, width 0.8s;
                        transform-origin: center;
                    }

                    &:hover::after {
                        width: 100%;
                    }
                    @media (max-width: 650px) {
                        white-space: normal;
                        font-size: 1.2rem;
                        &::after{
                            content: none;
                        }
                    }
                }
                .date{
                    font-weight: bold;
                }
            }
        }
    }
}
