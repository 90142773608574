.scroll-button{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    animation: bounce 1s ease infinite;

    button{
        cursor: pointer;
        font-size: 2rem;
        background: none;
        border: none;
        color: var(--primary);
        padding: 0;
    }
}

@keyframes bounce{
    from {bottom: 10px;}
    50%  {bottom: 15px;}
    to   {bottom: 10px;}
}

@media (max-width: 650px) {
    @keyframes bounce {
        from {
            bottom: 15%;
        }
        50% {
            bottom: 16%;
        }
        to {
            bottom: 15%;
        }
    }
}