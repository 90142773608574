:root {
  --primary-base: #03162a;
  --primary: #03162a;
  --secondary: #e9e7e2;
  --secondary-light: rgba(233, 231, 226, 0.45);
  --tertiary: black;
  --header: white;
  --link: #002D72;
  }

:root.dark {
  --primary: #e9e7e2;
  --secondary: #03162a;
  --secondary-light: rgba(3, 22, 42, 0.45);
  --tertiary: white;
  --header: white;
  --link: #AFD7FF;
}

html{
  &::-webkit-scrollbar {
    /* Hide scrollbar */
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
}

body {
  background-color: var(--secondary);
  color: var(--primary);
  transition: background 2s;
  margin: 0;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app-body{
  padding: 0 1rem 5rem 1rem;
  @media (max-width: 650px) {
    padding: 0 0.5rem 5rem 0.5rem;
  }
  min-height: 100vh;
}

h1{
  font-size: 4rem;
  font-weight: 500;
  margin: 0 0 0.5rem 0;
  @media (max-width: 1000px) {
    font-size: 3rem;
  }
  @media (max-width: 650px) {
    font-size: 2.5rem;
  }
}
h2{
  font-weight: normal;
  font-size: 1.5rem;
  margin: 0;
  @media (max-width: 650px) {
    font-size: 1.2rem;
  }
}

a{
  text-decoration: none;
  white-space: nowrap;
  color: var(--link);
  position: relative;
}

.italic{
  font-style: italic;
  font-size: 1rem;
  margin: 0;
  @media (max-width: 650px) {
    font-size: 0.8rem;
  }
}

.mediumFont{
  font-size: 1.2rem;
  @media (max-width: 650px) {
    font-size: 1rem;
  }
}

.smallFont{
  font-size: 1rem;
  margin: 0;
  @media (max-width: 650px) {
    font-size: 0.8rem;
  }
}