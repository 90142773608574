.home {
    .title{
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
    }
    .skills {
        @media (min-width: 900px) {
            margin: 0 auto;
            width: 890px;
        }
        .skill {
            text-align: center;
            margin-bottom: 4rem;
            h2{
                font-weight: bold;
                margin-bottom: 1rem;
            }
            .schema {
                display: flex;
                justify-content: center;
                align-items: end;
                height: 200px;
                gap: 1rem;
                font-weight: bold;
                font-size: 0.9rem;
                @media (max-width: 700px) {
                    height: 130px;
                    font-size: 0.7rem;
                    gap: 0.2rem;
                }
                @media (max-width: 500px) {
                    height: 120px;
                    font-size: 0.6rem;
                    gap: 0.1rem;
                }

                &.show {
                    .language, .framework, .development-tool, .security-tool {
                        animation: slideIn 2s forwards;
                    }
                }

                .language, .framework, .development-tool, .security-tool {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    opacity: 0;
                    transition: opacity 2s;
                    transform-origin: bottom;
                    color: var(--primary-base);
                    padding: 0.3rem;
                    @media (max-width: 500px) {
                        padding: 0.1rem;
                    }
                }
            }
        }
    }
}

@keyframes slideIn {
    from {
        opacity: 0;
        transform: scaleY(0);
    }
    to {
        opacity: 1;
        transform: scaleY(1);
    }
}