.text {
    width: 100%;
    display: flex;
    justify-content: center;
    .mediumFont{
        text-align: center;
        width: 50%;
        @media (max-width: 1000px) {
            width: 70%;
        }
        @media (max-width: 650px) {
            width: 100%;
        }
    }
}
